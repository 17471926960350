.blog-product {
  padding: 20px;
  background: var(--fi-blog-product-bg, #1ea4dc);

  .product {
    h5, p, li {
      color: var(--fi-secondary, #2b2e42);
    }

    &-description {
      font-size: 16px;
    }

    &-image {
      width: 100px;
      height: auto;
      margin-right: 20px;
    }

    &-slider {
      margin-bottom: 20px;
    }

    &-apr-container {
      margin-top: 20px;

    }
  }
}

#sub-page-content .blog-product {


}

#sub-page-content .light {
  li {
    &:before {
      color: var(--fi-secondary-900, #FFFFFF);
    }
  }

  h5, p, li {
    color: var(--fi-secondary-900, #FFFFFF);
  }
}
