#facts {
  background: var(--fi-well-facts-bg, #f6fafb);
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  .facts-heading {
    text-align: center;
    padding-bottom: 100px;
    @media only screen and (max-width: 576px) {
      padding-bottom: 50px;
      margin-bottom: 0;
    }
  }

  .fact {
    text-align: center;

    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 50px;
      @media only screen and (max-width: 900px) {
        gap: 40px;
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    &-heading {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $secondary;
      margin: 20px 0;
    }

    &-body {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $secondary;
    }
  }
}


