@import "colors.scss";
@import "fonts.scss";
@import "layout.scss";
@import "navigation.scss";
@import "facts.scss";
@import "faq.scss";
@import "calculator.scss";
@import "footer.scss";
@import "contentBlocks.scss";
@import "contactBlock.scss";
@import "cookieConsent.scss";
@import "introduction.scss";
@import "team.scss";
@import "appDownload.scss";
@import "advertisers.scss";
@import "partners.scss";
@import "products.scss";
@import "howDoesItWork.scss";
@import "blog.scss";
@import "blogPost.scss";
@import "breadcrumbs.scss";
@import "products/mainPageProducts.scss";
@import "products/blogPageProducts.scss";
@import "modal.scss";
@import "testimonials.scss";
@import "advantages.scss";
@import "creditCard.scss";
@import "latestBlogPosts.scss";
@import "mobile.scss";
@import "ad.scss";

:root {
  --fi-footer-bg-color: #{$color-white};
  --fi-footer-text-color: #{$secondary100};
  --fi-footer-text-highlight: #{$secondary};
  --fi-hero-text-color: #{$color-white};
  --fi-well-testimonials: #dcecf3;
  --fi-well-partners: #f6fafb;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

audio, canvas, embed, iframe, img, object, video {
  max-width: 100%;
  vertical-align: middle;
}

.header {
  width: 100%;
  height: 100px;
}


.card {
  border-radius: 12px;
  box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
  border: solid 1px #c4ccd9;
  background-color: #ffffff;
  padding: 30px 40px;
}

.grey-background {
  background-color: rgba(238, 245, 247, 0.5);
}

.img-link {
  position: relative;
  display: inline-block;
}
