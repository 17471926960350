#adsbygoogle-header {
  &.disabled {
    display: none;
  }

  ins {
    text-align: center;
  }

  display: block;
  text-align: center;
  width: 100% !important;
  height: 50px;
  bottom: auto;
  clear: none;
  float: none;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  opacity: 1;
  overflow: visible;
  padding: 0;
  position: fixed;
  right: auto;
  top: 0;
  vertical-align: baseline;
  visibility: hidden;
  z-index: 2147483647;
  background: rgb(250, 250, 250);
  -webkit-transform:translateY(-200%);
  -moz-transform:translateY(-200%);
  transform:translateY(-100%);
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;

  @media only screen and (max-width: 576px) {
    height: 50px;
  }

  &.expanded {
    visibility: visible;
    -webkit-transform:translateY(0%);
    -moz-transform:translateY(0%);
    transform:translateY(0%);
    -webkit-transition: transform 1s ease-in-out, height 1s ease-in-out;
    -moz-transition: transform 1s ease-in-out, height 1s ease-in-out;
    transition: transform 1s ease-in-out, height 1s ease-in-out;

    .header-ad-tag {
      visibility: visible;

      &.header-ad-tag-close {
        visibility: visible;
        -webkit-transition: top 1s ease-in-out !important;
        -moz-transition: top 1s ease-in-out !important;
        transition: top 1s ease-in-out !important;
      }

      &.header-ad-tag-open {
        display: none;
      }
    }
  }

  &.collapsed {
    visibility: visible;
    .header-ad-tag {
      visibility: visible;

      &.header-ad-tag-open {
        display: block;
      }

      &.header-ad-tag-close {
        visibility: hidden;
      }
    }
  }

  .header-ad-tag {
    visibility: hidden;
    position: absolute !important;
    top: 45px !important;
    width: 100% !important;
    z-index: -1;

    @media only screen and (max-width: 576px) {
      top: 45px !important;
    }

    svg {
      margin: 0 !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      display: block !important;
      width: 80px !important;
      height: 30px !important;
      transform: none !important;
      pointer-events: initial !important;
    }
  }
}


