.breadcrumbs {
  text-align: center;
  padding-bottom: 18px;
  & div {
    display: inline-block;
    & a {
      text-decoration: none;
      font-size: 21px;
      line-height: 1.43;
      color: #535567;
      padding-right: 15px;
    }
    & .active-crumb {
      font-size: 21px;
      font-weight: bold;
      line-height: 1.43;
      color: #2b2e42;
    }
  }
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent #8b99ae;
    padding-right: 10px;
    margin-bottom: 2px;
  }
}

.hide-title {
  padding: 30px 0;
}
