#content {
  .first-block {
    padding-top: 50px;
    @media only screen and (max-width: 576px) {
      padding-top: 40px;
    }
  }

  p {
    color: $secondary200;
    font-size: 16px;
  }

  .full-width {
    p {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $secondary100;
    }

    h2 {
      margin: 0 0 30px;
    }

    ol {
      padding-inline-start: 30px;
      text-align: left;

      li {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      ol {
        list-style: square;

        li {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  ul {
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
    margin-top: 20px;
  }

  li {
    margin-left: 20px;
    padding-bottom: 10px;
  }

  ul li:before {
    content: "\2022";
    color: #c4ccd9;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.arrow-right {
  display: inline-block;
  border-top: 2px solid $secondary100;
  border-left: 2px solid $secondary100;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 21px;
  right: 8px;
  position: absolute;

}

.arrow-up {
  display: inline-block;
  border-top: 2px solid $secondary100;
  border-left: 2px solid $secondary100;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-right: 8px;
}


#sub-page-content {
  #content {
    padding: 0;
  }

  #content .first-block {
    padding-top: 0;
  }

  .content-block {


    p, .full-width p {
      text-align: left;
      color: $secondary;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
    }

    h1 {
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $secondary;
      @media only screen and (max-width: 576px) {
        font-size: 50px;

      }
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $secondary;
      @media only screen and (max-width: 576px) {
        font-size: 21px;
      }
    }

    li {
      margin-left: 0;
      margin-bottom: 16px;
      line-height: 1.75;
      font-size: 16px;
    }

    ul {
      text-align: left;
      list-style: none;
      padding: 0;
      position: relative;
      margin-left: 0;

      li:before {
        content: "\2022";
        color: #c4ccd9;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      ul {
        margin-left: 10px;
        list-style: circle;

        li:before {
          content: "";
        }

        ul {
          list-style: square;
        }
      }

    }

    table {
      table-layout: fixed;
      width: 100%;
      overflow-wrap: break-word;
      border: 1px solid;
      border-collapse: collapse;

      th {
        border: 1px solid;
        font-weight: bold;
        text-align: center;
      }

      td {
        border: 1px solid;
        text-align: start;
      }
    }

  }

  .table-of-contents {
    text-align: left;

    ul {
      padding-left: 0;
      list-style: none;
    }

    li {
      position: relative;
    }

    li a {
      font-size: 18px;
      padding: 13px 15px;
      cursor: pointer;
      text-decoration: none;
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    li a:hover {
      font-weight: bold;
      background: $primary400;
    }

    ul li:before {
      display: none;
    }
  }

  .box {
    margin-bottom: 0;
  }

  .block-headline {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secondary;
    margin: 100px 0 30px 0;
    text-align: initial;
    @media only screen and (max-width: 576px) {
      font-size: 28px;
      margin: 80px 0 30px 0;
    }
  }


  .return-to-top {
    text-align: left;
    padding-top: 50px;

    a {
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: 0.5px;
      margin-top: 50px;
      cursor: pointer;
    }
  }

  .block {
    padding: 100px 0;
    @media only screen and (max-width: 576px) {
      padding: 60px 24px;
    }

    .box img {
      @media only screen and (max-width: 576px) {
        margin-bottom: 50px;
      }
    }
  }


  #content .row .col-xs-12:last-child img:last-child {
    @media only screen and (max-width: 576px) {
      margin-bottom: 0;
      margin-top: 50px;
    }
  }
}

.full-width-block {
  padding: 100px 0;
  @media only screen and (max-width: 576px) {
    padding: 80px 24px;
  }

  .box {
    margin-bottom: 30px;
  }
}

#unsubscribe-page {
  #content {
    padding-top: 0;

    h2, p {
      margin-bottom: 30px !important;
    }

    p {
      line-height: 1.75;
      color: $secondary;
    }

    .full-width-block {
      padding: 100px 0 0;
    }
  }
}

