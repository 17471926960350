#advantages-container {
  background: var(--fi-well-advantages-bg, #fff);
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  .advantages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px 70px;

    @media only screen and (max-width: 900px) {
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    .advantage {
      p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: var(--fi-well-advantages-text-color, #535567);
      }

      a {
        text-decoration: none;
      }

      &-heading {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        min-height: 40px;

        .advantage-index {
          display: none;
        }

        h3 {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: var(--fi-well-advantages-text-color, #2b2e42);
          margin-bottom: 0;
          display: inline-block;
        }

        img {
          margin-left: 10px;
          display: inline-block;
        }
      }

      &-img {
        margin-right: 10px;
      }
    }
  }
}
