.parallax-product-blocks {
  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 768px) {
    padding-right: 8px;
    padding-left: 8px;
  }

  .html-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-position: center;
    z-index: -1;
    opacity: 0;
    transition: opacity 1.0s ease;
    background-size: 1500px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -300px;
    @media only screen and (min-width: 768px) {
      background-position-y: top;
      background-size: auto;
    }

    &.active {
      opacity: 1;
    }
  }

  .html-desktop-background {
    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }

  .html-mobile-background {
    background-size: 768px;
    background-position-x: center;
    background-position-y: -76px;

    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  .hero-text {
    color: var(--fi-hero-text-color, #ffffff);
    padding: 16px;

    h1 {
      color: var(--fi-hero-text-color, #ffffff);
      font-size: 24px;
      font-weight: normal;

      @media only screen and (min-width: 768px) {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }

  .products-selection-tabs {
    .products-selection-tab {
      color: #fff;
    }
  }
}

.header-product-blocks {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='379' viewBox='0 0 1440 379' fill='none'%3E%3Cg clip-path='url(%237ly0w6p05a)' fill='%23DCECF3'%3E%3Cpath d='M78.022 245.404-147.86 368.965A44.492 44.492 0 0 0-171 408h1778v-92.647c0-123.315-78.89-232.803-195.86-271.839-82.43-27.509-172.9-16.126-245.95 30.947l-139.74 90.053a545.002 545.002 0 0 1-403.307 76.053l-219.377-44.392a478.809 478.809 0 0 0-324.744 49.229z' fill-opacity='.3'/%3E%3Cpath d='M30.022 261.404-195.86 384.965A44.492 44.492 0 0 0-219 424h1778v-92.647c0-123.315-78.89-232.803-195.86-271.839-82.43-27.509-172.9-16.126-245.95 30.947l-139.741 90.053a544.999 544.999 0 0 1-403.306 76.053l-219.377-44.392a478.809 478.809 0 0 0-324.744 49.229z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='7ly0w6p05a'%3E%3Cpath fill='%23fff' d='M0 0h1440v379H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-color: #eff6fc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  @media only screen and (min-width: 572px) {
    padding: 3rem;
  }

  @media only screen and (min-width: 768px) {
    padding: 5rem 0 0;
  }

  .headline-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .product-apr-container {
    position: relative !important;
    margin-top: 1rem;
  }
}

.blog-product {
  padding: 20px;
  background: var(--fi-blog-product-bg, #1ea4dc);

  .product-block {
    box-shadow: none;
    margin: 0;
    padding: 0;
    background: none;

    h2 {
      font-size: 24px;
      font-weight: 600;
    }

    h2, p, li {
      color: var(--fi-secondary, #2b2e42);
    }

    .product-header {
      .product-title-wrapper {
        .product-title {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }

    .product-description {
      font-size: 16px;
    }

    .product-icon {
      width: 100px;
      height: auto;
      margin-right: 20px;
    }

    .product-panel {
      p {
        color: var(--fi-secondary, #2b2e42);
        font-size: 16px;
      }

      ul {
        padding-left: 1rem;

        li {
          margin-bottom: 0;
          line-height: 1.75;
        }
      }
    }

    .product-slider {
      margin-bottom: 20px;
    }

    .product-apr {
      font-size: 10px;
      margin-top: 20px;

      p {
        font-size: inherit;
      }
    }
  }

  &.light {
    li {
      &:before {
        color: var(--fi-secondary-900, #FFFFFF);
      }
    }

    h2, p, li {
      color: var(--fi-secondary-900, #FFFFFF);
    }
  }
}

.product-blocks-wrapper {

}

.product-blocks {
  &.product-selection-blocks {
    .product-block {
      /* Hide block */
      @media only screen and (min-width: 768px) {
        display: none;

        .product-block-toggle {
          display: none;
        }
      }
    }
  }
}

.product-block {
  text-align: initial;
  background-color: rgba(255, 255, 255, 0.95);
  margin: 8px 16px;
  position: relative;
  border-radius: 24px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  display: block;
  padding: 16px;

  .product-block-toggle {
    position: absolute;
    right: 8px;
    top: 24px;
    transform: rotate(90deg);
    transition: all 0.4s ease;

    display: none;
  }

  .product-header {
    display: flex;
    align-items: center;

    .product-icon {
      display: inline-block;
      margin-right: 16px;
    }

    .product-title-wrapper {
      display: inline-block;
      width: calc(100% - 74px);

      .product-title {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 0;
      }

      .product-short-description {
        margin: 4px 0 0 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-slate-grey;
      }
    }
  }

  .product-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: relative;

    .product-long-description {
      p {
        margin: 1rem 0;
      }
    }

    .product-slider {
      margin-top: 24px;
    }

    .product-button {
      display: block;
      margin-top: 24px;
    }

    .product-button-link {
      box-shadow: var(--fi-button-shadow, none);
      background-color: var(--fi-primary, #ffc809);
      text-align: initial;
      position: relative;
      padding: 16px;
      border-radius: var(--fi-button-border-radius, 6px);
      color: var(--fi-button-color, #2b2e42);
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      display: flex;
      align-items: center;
      vertical-align: middle;
      justify-content: space-between;

      &:hover {
        background-color: var(--fi-primary-300, #ffde6c);
      }

      .button-text {
        white-space: nowrap;
        font-weight: bold;
      }

      .button-continue {
        svg {
          width: 15px;
          height: 24px;
          vertical-align: middle;
        }
      }
    }

    .product-button-disclaimer {
      font-size: 10px;
      margin: 16px 0 0;
      padding-left: 20pt;
      position: relative;
      display: block;

      &:before {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17'%3E%3Cscript xmlns=''/%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%238B99AE'%3E%3Cg%3E%3Cg%3E%3Cpath d='M10.304 14.633c1.072-.662 2.006-1.536 2.713-2.58 1.523-2.246 2.147-4.593 2.39-6.058.13-.788-.276-1.546-.991-1.896-.319-.159-.684-.37-1.076-.658-.566-.416-.908-1.005-1.113-1.522C11.96 1.243 11.308.8 10.582.8h-4.93c-.728 0-1.379.444-1.646 1.12-.203.517-.546 1.106-1.114 1.522-.393.29-.76.502-1.08.66-.71.348-1.118 1.1-.988 1.88.222 1.346.752 3.44 2.008 5.516.867 1.435 2.12 2.597 3.56 3.456l.6.358c.69.415 1.555.408 2.24-.016l1.072-.662z' transform='translate(-20 -496) translate(20 494) translate(0 2)'/%3E%3Cpath d='M5.101 7.956L7.451 10.088 11.502 6.12' transform='translate(-20 -496) translate(20 494) translate(0 2)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");
        width: 16pt;
        height: 16pt;
        left: 0;
        top: 0;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: var(--fi-secondary-400, $color-blue-grey);
        border-left: 1px solid #c5ccd6;
        padding-left: 10pt;
      }
    }
  }

  .product-apr {
    font-size: 10px;
  }

  &.active {
    display: block !important;

    .product-block-toggle {
      transform: rotate(-90deg);
    }
  }

  &.collapsible {
    .product-block-toggle {
      display: block;
    }
  }
}

.product-apr-container {

  @media only screen and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    width: inherit;
  }

  .product-apr-block {
    margin: 0;
    padding: 0;

    .product-selection-apr {
      display: none;
      position: relative;
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 0 0 rgba(196, 204, 217, 0.7);
      padding: 6px 12px 6px 30px;

      @media only screen and (min-width: 768px) {
        border: 1px solid #c4ccd9;
        border-radius: 6px;
      }

      &:before {
        content: '';
        position: absolute;
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3Ij4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICA8ZyBzdHJva2U9IiMyYjJlNDIiPgogICAgICA8Zz4KICAgICAgICA8Zz4KICAgICAgICAgIDxwYXRoIGQ9Ik0xMC4zMDQgMTQuNjMzYzEuMDcyLS42NjIgMi4wMDYtMS41MzYgMi43MTMtMi41OCAxLjUyMy0yLjI0NiAyLjE0Ny00LjU5MyAyLjM5LTYuMDU4LjEzLS43ODgtLjI3Ni0xLjU0Ni0uOTkxLTEuODk2LS4zMTktLjE1OS0uNjg0LS4zNy0xLjA3Ni0uNjU4LS41NjYtLjQxNi0uOTA4LTEuMDA1LTEuMTEzLTEuNTIyQzExLjk2IDEuMjQzIDExLjMwOC44IDEwLjU4Mi44aC00LjkzYy0uNzI4IDAtMS4zNzkuNDQ0LTEuNjQ2IDEuMTItLjIwMy41MTctLjU0NiAxLjEwNi0xLjExNCAxLjUyMi0uMzkzLjI5LS43Ni41MDItMS4wOC42Ni0uNzEuMzQ4LTEuMTE4IDEuMS0uOTg4IDEuODguMjIyIDEuMzQ2Ljc1MiAzLjQ0IDIuMDA4IDUuNTE2Ljg2NyAxLjQzNSAyLjEyIDIuNTk3IDMuNTYgMy40NTZsLjYuMzU4Yy42OS40MTUgMS41NTUuNDA4IDIuMjQtLjAxNmwxLjA3Mi0uNjYyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwIC00OTYpIHRyYW5zbGF0ZSgyMCA0OTQpIHRyYW5zbGF0ZSgwIDIpIj48L3BhdGg+CiAgICAgICAgICA8cGF0aCBkPSJNNS4xMDEgNy45NTZMNy40NTEgMTAuMDg4IDExLjUwMiA2LjEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjAgLTQ5NikgdHJhbnNsYXRlKDIwIDQ5NCkgdHJhbnNsYXRlKDAgMikiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICAgIDwvZz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=") no-repeat;
        width: 16px;
        height: 16px;
        left: 6px;
        top: 6px;
      }

      &.active {
        display: block;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: $color-dark;
        text-align: justify;
        border-left: 1px solid $color-dark;
        padding-left: 10pt;
      }
    }
  }
}

.products-selection-tabs {
  .products-selection-tab {
    display: inline-block;
    color: $color-blue-grey;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $color-dark;
    }
  }
}

