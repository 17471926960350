#footer {
  background-color: var(--fi-footer-bg-color, $color-white);
  border-top: 1px solid $secondary600;
  padding: 100px 0;
  @media only screen and (max-width: 576px) {
    padding: 80px 24px;
  }

  .footer-logo:hover {
    // filter: brightness(0.5);
  }

  .footer-logo {
    margin-bottom: 30px;
  }

  #footer-partners {
    margin-bottom: 16px;

    a {
      text-decoration: none;
    }

    a img {
      margin-right: 16px;
    }

    a:hover {
      opacity: 0.8;
    }
  }

  &-terms p, &-menu p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--fi-footer-text-color, $secondary100);
  }

  &-terms {
    a {
      color: var(--fi-footer-text-color, $secondary100) !important;
    }

    a:hover, .is-active {
      color: var(--fi-footer-text-highlight, $secondary) !important;
    }
  }

  &-menu {
    p {
      font-size: 14px;
    }

    .footer-menu {
      h4 {
        color: var(--fi-footer-text-color, $secondary100);
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        @media only screen and (min-width: 1200px) {
          margin-top: 0;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          color: var(--fi-footer-text-color, $secondary100);
          margin-bottom: 20px;
          font-size: 14px;

          a {
            color: var(--fi-footer-text-color, $secondary100) !important;
            text-decoration: none;
          }

          a:hover, .is-active {
            color: var(--fi-footer-text-highlight, $secondary) !important;
            text-decoration: none;
          }

          button {
            background: none!important;
            border: none;
            color: var(--fi-footer-text-color, $secondary100) !important;
            text-decoration: none;
            padding: 0;
            cursor: pointer;
          }

          button:hover {
            color: var(--fi-footer-text-highlight, $secondary) !important;
            text-decoration: none;
          }
        }
      }
    }
  }

  .app-dl-links, .social-links {
    margin: 30px 0;

    ul {
      list-style-type: none;
      padding: 0;
      display: inline-block;
      margin: 0;

      li {
        display: inline;
        margin-right: 16px;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .social-links {
      margin: 15px 0 30px 0;
    }
    .app-dl-links {
      margin: 0 0 30px;
    }
  }

  .follow-us {
    margin-right: 16px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $secondary;
  }

  .app-dl-links {
    float: right;
    @media only screen and (max-width: 576px) {
      float: left;
    }
  }
}

.footer {
  color: var(--fi-footer-text-color, #535567);
  font-size: 0.875rem;
  background-color: var(--fi-footer-bg-color, $color-white);
  border-top: 1px solid $secondary600;
  padding: 2rem;

  @media only screen and (min-width: 768px) {
    padding: 5rem 0;
  }

  a {
    color: inherit;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    font-size: inherit;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .footer-navigation {
    display: grid;
    gap: 1rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 768px) {
      gap: 2rem;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    @media only screen and (min-width: 1024px) {
      gap: 3rem;
    }

    .footer-logo {
      margin-bottom: 1rem;

      @media only screen and (min-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    .footer-menu {
      h4 {
        font-size: 1.125rem;
        font-weight: bold;
        text-align: left;
        margin: 1rem 0;

        @media only screen and (min-width: 768px) {
          margin-top: 0;
        }
      }

      ul {
        li {
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }

  .social-links, .app-dl-links {
    margin-top: 1rem;

    ul {
      display: inline-flex;

      li {
        display: inline-block;
        margin-right: .5rem;
      }
    }
  }

  .footer-terms {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal
  }

}
