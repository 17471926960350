.introduction {
  padding: 65px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='665' height='240' viewBox='0 0 665 240'%3E%3Cscript xmlns=''/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='96.491%25' x2='19.003%25' y1='41.156%25' y2='54.333%25'%3E%3Cstop offset='0%25' stop-color='%23DCECF3'/%3E%3Cstop offset='100%25' stop-color='%23FFF'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='104.526%25' x2='-12.694%25' y1='54.008%25' y2='52.246%25'%3E%3Cstop offset='0%25' stop-color='%23FFF'/%3E%3Cstop offset='100%25' stop-color='%23DCECF3'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='url(%23a)' d='M56.747 94.525C30.456 90.91-4.386 118.088.454 168.36c4.838 50.274 51.614 71.324 51.614 71.324l.324.316h356.464c39.672-21.713 55.031-70.315 55.144-109.584v-.875c-.039-13.24-1.815-25.38-4.978-35.016-12.421-37.706-43.39-80.436-118.07-92.692C333.234.581 325.767 0 318.55 0 256.1 0 212.634 43.497 190.947 70.958c-24.196 30.793-107.746 27.021-134.2 23.567z'/%3E%3Cpath fill='url(%23b)' d='M311.445 113.921c-22.778-3.133-52.964 20.422-48.77 63.991 4.19 43.571 44.716 61.814 44.716 61.814l.281.274h308.833c34.371-18.818 47.678-60.94 47.776-94.973v-.758c-.034-11.474-1.572-21.996-4.313-30.348-10.761-32.678-37.592-69.71-102.294-80.332A120.975 120.975 0 0 0 538.266 32c-54.106 0-91.764 37.697-110.552 61.497-20.963 26.687-93.35 23.418-116.269 20.424z' transform='matrix(-1 0 0 1 926.561 0)'/%3E%3C/g%3E%3Cscript xmlns=''/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  background-color: #dcecf3;
  @media only screen and (max-width: 576px) {
    padding: 30px;
  }

  &-titles {
    text-align: center;
    margin: auto;

    h1 {
      font-size: 48px;
      font-weight: bold;
      margin: 0;
      @media only screen and (max-width: 576px) {
        font-size: 36px;
        line-height: 1.22;
      }
    }
  }
}
