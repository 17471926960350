#latest-blog-posts {
  background: #f6fafb;
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }
  .latest-blog-posts-heading {
    text-align: center;
    padding-bottom: 80px;
    @media only screen and (max-width: 576px) {
      padding-bottom: 50px;
      margin-bottom: 0;
    }
  }

  .blog-posts-container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }

    .blog-post {
      border-radius: 12px;
      border: solid 1px #c4ccd9;
      background-color: #ffffff;
      position: relative;

      &:hover {
        box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
      }

      &-image img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        object-fit: cover;
        height: 180px;
        width: 100%;
      }

      &-content {
        padding: 24px;
        text-align: left;
        position: relative;
        @media only screen and (max-width: 576px) {
          padding: 20px;
        }
      }

      &-title {
        margin-bottom: 16px;

        a {
          text-decoration: none;
        }

        h5 {
          font-weight: bold;
        }
      }

      &-read-more {
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
        a {
          z-index: 1;
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
          min-width: 175px;
          @media only screen and (max-width: 576px) {
            min-width: 146px;
          }
        }
        &:not(.full):after {
          content: "";
          display: block;
          position: absolute;
          height: 127px;
          width: 100%;
          bottom: 0;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 69%);
        }
      }
    }
  }

  .continue-to-blog-text p {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: var(--fi-secondary-100, #535567);
    margin-top: 47px;
    a {
      text-decoration: underline;
    }
  }
}

