.blog-header {
  display: none;
  text-align: center;
  background: #dcecf3;

  @media only screen and (min-width: 576px) {
    display: block;
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secondary;
    padding: 40px 0 10px 0;

    @media only screen and (min-width: 576px) {
      font-size: 60px;
      padding: 50px 0 10px 0;
    }
  }
}

.categories {
  background: #c3d4db;
  text-align: center;
  padding: 12px 0;

  .category {
    display: inline-flex;
    flex: 1;
    padding: 4px 15px;

    & a {
      text-decoration: none;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #535567;
    }
  }

  .active-category {
    border-radius: 6px;
    background-color: #ffffff;
  }
}

.featured-blog-post {
  margin: 80px 0;
  @media only screen and (max-width: 992px) {
    margin: 40px 22px;
  }

  img {
    border-radius: 60px;
    object-fit: cover;
    height: 500px;
    width: 100%;
    @media only screen and (max-width: 992px) {
      height: 300px;
      margin-bottom: 40px;
    }
  }

  &-category {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;

    h2 {
      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #535567;
      margin-bottom: 30px;
    }
  }

  &-body {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #535567;
  }
}

.blog-posts {
  display: grid;
  grid-gap: 80px 50px;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 80px;

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    padding: 0 22px;
  }
}

.blog-card {
  display: grid;
  grid-column: span 2;
  border-radius: 12px;
  border: solid 1px #c4ccd9;
  background-color: #ffffff;

  &:hover {
    box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
  }

  &-image img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
    height: 180px;
    width: 100%;
  }

  &-content {
    padding: 20px;
    text-align: left;
  }

  &-category {
    margin-bottom: 10px;
  }

  &-title {
    margin-bottom: 20px;

    a {
      text-decoration: none;
    }

    h5 {
      font-weight: bold;
    }
  }
}

.blog-card-2 {
  @media only screen and (min-width: 992px) {
    grid-column: span 3;
  }
}

.category-link {
  font-size: 16px;
  line-height: 1.88;
  color: #8b99ae;
  text-decoration: none;
  text-transform: uppercase;
}

.pagination {
  margin: 0 0 80px 0;
}

.pagination-list {
  .is-current {
    border-radius: 6px;
    background: #ffde6c;

    a {
      font-weight: bold;
    }
  }

  .pagination-link {
    text-decoration: none;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: center;
    color: #2b2e42;
  }

  .pagination-number {
    display: inline-block;
    padding: 10px 15px;
    @media only screen and (max-width: 700px) {
      margin: 30px 0;
    }

  }
}

.next-prev-button-container {
  display: inline-block;
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
}

.next-prev-button {
  text-transform: uppercase;

  & div {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: center;
    color: #2b2e42;
    padding: 10px;
    min-width: 113px;
    border-radius: 6px;
    border: solid 1px #2b2e42;
  }
}
