.step-header {
  padding: 22px;
  background: var(--fi-process-step-background, linear-gradient(to bottom, #d8eff9, #ffffff));
}
.credit-card-comparison {
  padding: 22px;
}
.header-text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fi-secondary, #2b2e42);
  text-align: left;
  @media only screen and (max-width: 575px) {
    font-size: 18px;
  }
}

.credit-card {
  padding: 16px 20px;
  border-radius: 6px;
  box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.6);
  border: solid 1px #c4ccd9;
  margin-top: 24px;

  &-company {
    font-size: 16px;
    font-weight: bold;
  }

  &-rating-text {
    font-size: 10px;
    color: #6d798c;
  }
  .additional-data {
    margin: 10px 0;

    .info {
      &-heading {
        font-size: 12px;
        min-height: 30px;
        margin-bottom: 4px;
      }
      &-detail {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .tabcontent-container {
    display: none;
  }
  .active-content {
    display: block !important;
  }

  .tabs {
    border-bottom: 1px solid #d1d6df;
    margin: 0 -20px;

    .tablinks {
      font-size: 16px;
      padding: 16px 0;
      color: #d1d6df;
      cursor: pointer;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
    }

    .active {
      border-bottom: 3px solid #2b2e42;
      color: #2b2e42;
    }
  }

  .show-more {
    cursor: pointer;
    .col-xs {
      font-size: 16px;
      font-weight: bold;
      margin-top: 16px;
    }

  }

  .active-show-more img {
    transform: rotate(180deg);
  }

  .tabcontent {
    display: none;
    padding-top: 16px;
    ul {
      margin-top: 0;
      padding-left: 24px !important;
    }
  }

}