@import "../colors";

.parallax {
  position: relative;

  .hero-text {
    height: 124px;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    width: 72%;

    h1 {
      color: var(--fi-hero-text-color, $secondary900);
      font-size: 24px;
      font-weight: normal;
    }
  }

  .products {
    padding-top: 10px;
  }

  .product {
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.95);
    margin: 8px 16px;
    position: relative;
    border: 1px solid $secondary500;
    box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
    display: block;

    @media only screen and (min-width: 768px) {
      display: none;
    }

    &-description {
      padding: 12px 16px 0 12px;
    }

    &.active {
      display: block;
    }
  }

  .product > div:nth-child(1) {
    height: 54px;
  }

  .product h2 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }

  .active {
    border: solid 1px $secondary500;
  }

  .product p {
    margin: 4px 0 0 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-slate-grey;
  }

  .product .panel {
    p {
      margin: 16px 0;
    }

    .button-disclaimer {
      p {
        font-size: 10px;
        margin: 16px 0 0 20pt;
      }
    }
  }

  .product img.arrow {
    position: absolute;
    right: 8px;
    top: 24px;
    transform: rotate(90deg);
    transition: all 0.4s ease;
  }

  .active img.arrow {
    transform: rotate(-90deg);
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding: 0 16px 12px 16px;
    position: relative;
  }

  .preload * {
    transition: none !important;
  }

  .html-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-position: center;
    z-index: -1;
    opacity: 0;
    transition: opacity 1.0s ease;
    background-size: 1500px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -300px;
    @media only screen and (min-width: 768px) {
      background-position-y: top;
      background-size: auto;
    }
  }

  // if no custom background are configured, then use default bg
  .html-background:not(:has(+ .html-background)) {
    opacity: 1;
  }

  .html-bg-0 {
    z-index: -2;
    background-size: 768px;
    background-position-x: center;
    background-position-y: -76px;
    opacity: 1;
    @media only screen and (min-width: 768px) {
      background-position-y: top;
      background-size: auto;
    }
  }

  .html-bg-1 {
    background-image: url(https://cdn.finzmo.com/images/backgrounds/2.jpg);
    z-index: -3;
    background-size: 768px;
    background-position-x: center;
    background-position-y: top;
    opacity: 0;
    @media only screen and (min-width: 768px) {
      background-image: url(https://cdn.finzmo.com/images/backgrounds/2-3840x751-1.jpg);
      background-position-y: top;
      background-size: auto;
    }
  }

  .html-bg-2 {
    background-image: url(https://cdn.finzmo.com/images/backgrounds/00.jpg);
    z-index: -4;
    opacity: 0;
    background-size: 1500px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -300px;
    @media only screen and (min-width: 768px) {
      background-image: url(https://cdn.finzmo.com/images/backgrounds/3840x751.jpg);
      background-position-y: top;
      background-size: auto;
    }
  }

  .html-bg-3 {
    background-image: url(https://cdn.finzmo.com/images/backgrounds/1.jpg);
    z-index: -5;
    background-size: 768px;
    background-position-x: center;
    background-position-y: -76px;
    opacity: 0;
    @media only screen and (min-width: 768px) {
      background-image: url(https://cdn.finzmo.com/images/backgrounds/3-3840x751.jpg);
      background-position-y: top;
      background-size: auto;
    }
  }

  .product-selection {
    cursor: pointer;
  }

  .product-selection-desktop {
    display: none;
  }

  .product-image {
    display: inline-block;
    margin-right: 16px;
  }

  .product-slider {
    margin-bottom: 16px;
  }

  .products-selection-tabs {
    display: flex;
    background: #fff;
  }

  .product-selection-tab {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    color: $secondary900;
    font-size: 0.9rem;
    background-color: $color-dark;

    &.active-desktop-product {
      color: $color-dark;
      font-weight: bold;
      background-color: $secondary900;
    }

    &:nth-child(1) {
      padding-left: 24px;
    }

    &:last-child {
      flex: 1;
    }

    @media only screen and (min-width: 992px) {
      width: auto;
      white-space: nowrap;

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    min-height: 659px;

    .hero-text {

      padding-top: 115px;
      padding-bottom: 43px;
      width: 100%;
      padding-left: 0;

      h1 {
        font-size: 40px;
        font-weight: 600;

      }
    }

    .product-selection-desktop {
      display: inline-block;
      padding: 16px 24px;
      border-radius: 30px;
      color: $secondary900;
      margin: 0 16px 16px 0;
      font-size: 18px;
      font-weight: bold;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(10px);
    }

    .product-selection-desktop:hover {
      cursor: pointer;
    }

    .product-selection-desktop.active-desktop-product {
      background: $secondary900;
      color: $color-dark;
      box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
    }

    .product {
      margin-top: 115px;
      border-radius: 20px;
      backdrop-filter: blur(10px);
      box-shadow: 0 20px 35px 5px rgba(83, 85, 103, 0.5);
      background-color: $secondary900;
      display: none;

      &-description {
        padding: 24px 24px 0 24px;
      }

      h2 {
        font-size: 18px;
      }

      .panel {
        padding: 0 24px 24px 24px;

        p {
          font-size: 16px;
        }

        .button-disclaimer {
          p {
            font-size: 10px;
            margin: 16px 0 0 20pt;
          }
        }
      }
    }
    .active {
      border: none;
    }

    .arrow {
      display: none;
    }

    .product-slider {
      margin-bottom: 24px;
    }
    .products-container {
      min-width: 400px;
    }

    .product-apr-container {
      position: absolute;
      bottom: 0;
      width: inherit;

      .product-apr-block {
        .product-selection-apr {
          margin: 16px 0 16px 0;
          border: 1px solid #c4ccd9;
          border-radius: 6px;
          box-shadow: 0 20px 15px -15px rgba(196, 204, 217, 0.7);
          padding: 6px 12px 6px 6px !important;

          p {
            &:before {
              left: 6px !important;
            }
          }
        }
      }
    }
  }

  .button-disclaimer {
    margin: 16px 0;
    position: relative;
    display: block;

    p {
      font-size: 10px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: var(--fi-secondary-400, $color-blue-grey);
      border-left: 1px solid #c5ccd6;
      padding-left: 10pt;
      margin: 16px 0 0 20pt;

      &:before {
        content: "";
        position: absolute;
        background: url(https://cdn.finzmo.com/ceres/aprIcon.svg) no-repeat;
        width: 16pt;
        height: 16pt;
        left: 0;
        top: 0;
      }
    }
  }
}
