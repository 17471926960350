#team-members {
  background: #dcecf3;


  h2 {
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 50px;
    @media only screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  .block img {
    margin: 0;
  }


  .team-member {
    margin: 10px;
    height: calc(100% - 20px);

    .row {
      height: 100%;
      background: #fff;
    }

    &-details {
      padding: 30px 20px;
      vertical-align: top;


      .position {
        color: $secondary400;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 5px;
      }

      .employee-name {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $secondary;
        margin-bottom: 20px;
      }

      .description {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: $secondary100;
      }
    }
  }
}
