.testimonials-container {
  background: var(--fi-well-testimonials-bg, #dcecf3);
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  h2.testimonials-header {
    color: var(--fi-well-testimonials-text-color, #2b2e42);
    text-align: center;
    margin-bottom: 40px;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  .testimonials {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 12px;
    border: solid 1px #c4ccd9;
    background: #FFFFFF;

    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }

    .testimonial {
      padding: 40px;
      display: inline-block;
      border-right: 1px solid #c4ccd9;

      &:last-child {
        border-right: none;
        border-bottom: none;
      }

      @media only screen and (max-width: 900px) {
        border-right: none;
        border-bottom: 1px solid #c4ccd9;
        padding: 20px;
      }

      &-heading {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }

      &-image {
        margin-right: 20px;
        display: inline-block;

        img {
          border-radius: 50%;
          height: 50px;
          width: 50px;
        }

      }

      &-author {
        font-size: 18px;
        font-weight: bold;
      }

      &-rating > span {
        position: relative;
        width: 1.1em;
        color: gold;
      }

      .testimonial-author-container {
        display: inline-block;
      }

      &-text {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: var(--fi-secondary-100, #535567);
      }
    }
  }
}

.testimonials-component {
  .headline {
    justify-content: center;
  }
  .rating {
    color: gold;
    font-size: 2rem;
    justify-content: center;
    margin-top: .5rem;
  }
  .rating-summary {
    margin-top: .5rem;
    justify-content: center;
  }
  .testimonials {
    margin-top: 2rem;
    justify-content: center;
  }
  .testimonial {
    display: flex;
    max-width: 560px;
    gap: 1rem;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    padding: .5rem;

    .photo {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
    .details {
      flex: 1;

      .author {
        color: #3082c5;
        margin-bottom: .5rem;
      }

      .summary {

      }
    }
  }
}
