.how-does-it-work {
  background-color: var(--fi-well-how-it-works-bg, $color-white);
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  h2 {
    margin-bottom: 40px;
  }

  &-block {
    margin-bottom: 20px;
  }

  &-text {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
  }

  &-picture img {
    width: 100px;
    @media only screen and (max-width: 576px) {
      width: 80px;
    }
  }
}
