@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: $secondary;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
}

h1, h2, h3, h4, h5, p, strong {
  margin: 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 42px;
  @media only screen and (max-width: 576px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

h1, h2 {
  font-weight: bold;
}

h3 {
  font-size: 40px;
  @media only screen and (max-width: 576px) {
    font-size: 21px;
    margin-bottom: 20px;
  }
}

h4 {
  font-size: 32px;
  margin: 30px 0;
  @media only screen and (max-width: 576px) {
    margin: 20px 0;
  }
}

h5 {
  font-size: 24px;
}

a {
  text-decoration: none;
  color: $secondary;
}

p {
  font-size: 16px;
  line-height: 1.5;

  a {
    font-weight: bold;
  }
}

strong, b {
  font-weight: bold;
}


