@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/components/navbar.sass";

.navbar {
  background: transparent;
  @media screen and (max-width: 1023px) {
    height: 69px;
  }

  &-menu {
    @media screen and (max-width: 1023px) {
      margin: 0;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      overflow: auto;
      background: white;
      box-sizing: border-box;
      padding: 90px 30px 30px;
      display: block;
      opacity: 0;
      left: 0;
      transform: translateX(100%);
      transition: all .2s linear;
      pointer-events: none;
    }

    &.is-active {
      opacity: 1;
      transform: none;
      pointer-events: auto;
    }
  }

  @media screen and (max-width: 1023px) {
    &-brand {
      .navbar-item {
        position: absolute;
        top: 20px;
        left: 24px;
        padding: 0;
      }
    }
  }

  &-item {
    padding: 0;
    @media screen and (max-width: 1023px) {
      padding: 30px 0 0;
    }
  }

  &-burger {
    @media screen and (max-width: 1023px) {
      height: 69px;
    }

    span {
      background-color: $color-white;
      width: 20px;
      height: 2px;
    }

    &.is-active {
      position: fixed;
      z-index: 1000;
      right: 0;
      span {
        background-color: var(--fi-secondary, #2b2e42);
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.navbar-wrapper {
  max-width: 1400px;
  margin: 0 auto;

  .header-logo {
    height: 40px;
    max-height: initial;
    @media screen and (max-width: 1023px) {
      height: 30px;
    }
  }
}

.menu-list {
  li {
    display: inline-block;
    font-size: 0;
  }

  li:first-child a {
    @media screen and (min-width: 1023px) {
      margin-left: 38px;
    }
  }

  li a {
    display: inline-block;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-white;
    text-decoration: none;
    margin-right: 50px;
  }

  a:hover, .is-active a {
    font-weight: bold;
  }

  a::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .profile-link {
    width: 175px;
    position: absolute;
    right: 50px;
    margin-top: -8px;
  }

  @media screen and (max-width: 1023px) {
    li, li a {
      display: block;
      text-align: left;
      color: var(--fi-secondary, #2b2e42);
    }
    .profile-link {
      width: 100%;
      position: inherit;
      margin-right: 0;
      margin-top: 50px;
    }
  }
}

ul.menu-list {
  padding: 0;
}


#profile-button {
  display: none;
}

.logged-in {
  #login-button {
    display: none;
  }

  #profile-button {
    display: block;

    & img {
      padding-right: 8px;
    }
  }
  .dark {
    #profile-button{
      & img {
        filter: none;
      }
      &:hover img {
        filter: #{"invert()"};
      }
    }
  }
}

.e-commerce-header-container {
  padding: 16px;
}

.dark {
  .navbar-item a {
    color: var(--fi-secondary, #2b2e42);
  }

  .navbar-burger span {
    background-color: var(--fi-secondary, #2b2e42);
  }
}

.block {
  &.navigation-block {
    padding-top: 0;
  }
  .navigation-container {
    display: flex;
    justify-content: center;

    .navigation-well {
      width: 300px;
      border-radius: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.00);
      background: #EFF6FC;
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
      padding: 2rem 5rem;

      .headline {
        justify-content: center;
      }

      .nav {
        justify-content: center;

        nav {
          > ul {
            display: flex;
            gap: 2rem;
            justify-content: space-between;
            padding: 0;
            list-style: none;

            ul {
              padding: 0;
              list-style: none;

              a {
                font-weight: 600;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.navigation-footer {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  > ul {
    display: flex;
    flex-direction: row;
    gap: 3rem;

    h4 {
      margin: 0;
    }
  }
}
