.partners {
  background: var(--fi-well-partners-bg, #f6fafb);
  padding: 80px 0;
  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  h2 {
    color: var(--fi-well-partners-text-color, #2b2e42);
    margin-bottom: 50px;
  }

  .partners-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;

    .partner-logo {
      padding: 5px;

      img {
        width: 90px;
        height: auto;
        vertical-align: baseline;
        @media only screen and (max-width: 576px) {
          max-width: 80px;
        }
      }
    }
  }
}
