.mobile {
  .header-container {
    position: absolute;
    width: 100%;
  }

  .products-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 1.5rem;

    .hero-text {
      & p {
        font-size: 2rem;
      }
    }

    .product-slider {
      margin-bottom: 16px;
    }

    .button-disclaimer {
      font-style: italic;
      text-align: center;
      margin-top: 0.5rem;

      & p {
        font-size: 0.75rem;
      }
    }
  }
}
