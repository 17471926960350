.contact {
  background: #eef5f7;

  &-blocks {
    margin-top: 50px;
  }

  .block {
    padding-bottom: 0;
    @media only screen and (min-width: 576px) {
      padding: 29px 0 0 0;
    }
  }

  .box {
    margin-bottom: 0;
  }

  p {
    color: $secondary100;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
  }
  p, h2 {
    margin-bottom: 30px;
    @media only screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  img {
    padding-top: 51px;
    @media only screen and (max-width: 1200px) {
      padding-top: 20px;
      margin-top: 0;
    }
  }

  &-button {
    width: 250px;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}

.contact-page {
  p {
    text-align: center !important;
  }

  h2 {
    font-size: 32px !important;
    font-weight: bold !important;
  }

  h3 {
    font-size: 21px !important;
    font-weight: bold !important;
  }

  .contact-type {
    border-radius: 6px;
    border: solid 1px #c4ccd9;
    background-color: #ffffff;
    text-align: center;
    padding: 30px;
    @media only screen and (max-width: 576px) {
      margin-bottom: 40px;
    }

    p {
      text-align: center !important;
      margin-top: 0;
    }

    img {
      margin-bottom: 20px;
    }
  }

}
