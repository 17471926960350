/* https://github.com/evgenyrodionov/flexboxgrid2 */
@import "flexboxgrid2.min.css";

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.block {
  position: relative;
  padding: 80px 0;

  @media only screen and (max-width: 576px) {
    padding: 60px 22px;
  }

  &-headline {
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $secondary;
    margin-bottom: 100px;
    @media only screen and (max-width: 576px) {
      font-size: 28px;
      margin-bottom: 50px;
    }
  }

  &-dark {
    background-color: #eff6fc;
  }

  &-space {
    margin: 1rem 0;
  }
}

.box {
  margin-bottom: 16px;

  &-content {
    max-width: 350px;
  }

  h1 {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secondary;
    margin-top: 0;
  }

  @media only screen and (min-width: 576px) {

    &-padding-right-110 {
      padding-right: 110px;
    }

    &-padding-left-110 {
      padding-left: 110px;
    }
  }
}

.header-image {
  position: relative;
  background-image: url("https://cdn.finzmo.com/ceres/finzmohero6.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% calc(100% + 21px);

  h1 {
    font-size: 54px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b2e42;
    padding-left: 22px;
    padding-top: 54px;
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2e42;
      padding-top: 24px;
    }
  }

  @media only screen and (max-width: 1024px) {
    min-height: 179px;
    background-position-x: 100%;
    background-position-y: 16px;
    background-size: 367px;
  }
  @media only screen and (max-width: 800px) {
    min-height: 179px;
    background-position-x: 100%;
    background-position-y: 16px;
    background-size: 500px;
  }
  @media only screen and (max-width: 767px) {
    min-height: 179px;
    background-position-x: 100%;
    background-position-y: 16px;
    background-size: 237px;
  }

}

.header-container {
  border-bottom: 1px solid $secondary600;
}

.gradiant-bg {
  background-image: linear-gradient(134deg, rgba(0, 0, 0, 0.39) 23%, rgba(0, 0, 0, 0) 61%);
}
.no-products {
  background-image: url(https://cdn.finzmo.com/system/icons/field/bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  @media only screen and (max-width: 576px) {
    background-position: top 10px left 0;
    background-image: url(https://cdn.finzmo.com/system/icons/field/bg-mobile.svg);
  }
}

.self-service {
  .header-container {
    background: none;
  }
}

#unsubscribe {
  margin: 0 24px;

  .box {
    margin-bottom: 30px;
  }
  .unsubscribe-message {
    text-align: center;
  }
}

.form-message {
  &-hidden {
    display: none;
  }

  &-error, &-error li {
    color: $errortint;
    margin: 24px 0;
  }

  &-success {
    color: $primary;
    margin: 24px 0;
  }
}

.border-bottom {
  border-bottom: 1px solid $secondary600;
}

figure {
  margin: 0;
}

.name-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.cta-button {
  margin: 16px 0;
}
