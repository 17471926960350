.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  background-color: rgba(43, 46, 66, 0.6);
  display: none;
}

.scale-in {
  display: table;
  animation: scaleIn ease 0.3s;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.scale-out {
  animation: scaleOut ease 5s;
}

@keyframes scaleOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 30px;
}

.modal-container {
  max-width: 355px;
  padding: 20px;
  transition: all 0.3s ease;
  border-radius: 8px;
  border: solid 1px var(--fi-secondary-600, #d1d6df);
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
}

.modal-header {
  &.collapsed {
    margin: -20px -20px 0 -20px;
  }
}

.modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 120px);

  table {
    width: 100%;
  }
}
