.category {
  text-align: center;
  padding: 40px 0 20px 0;

  .category-link {
    font-size: 16px;
    line-height: 1.88;
    text-align: center;
    color: #8b99ae;
    text-decoration: none;
    text-transform: uppercase;
  }
}

#sub-page-content {

  .blog-post {
    .blog-featured-img {
      margin: 40px 0 20px;
      @media only screen and (max-width: 576px) {
        margin: 40px 24px 20px 24px;
      }
    }
    .blog-featured-img img, .content img {
      border-radius: 60px;
      object-fit: cover;
      height: 450px;
      width: 100%;

      @media only screen and (max-width: 576px) {
        height: 200px;
        border-radius: 10px;
        width: auto;
      }
    }

    .block {
      padding: 20px 24px;
    }
    h1 {
      font-size: 48px;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: left;
      strong {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8b99ae;
      }
    }
    .box p {
      text-align: justify;
    }
    .block-headline {
      text-align: center;
      margin: 0 0 20px 0;
    }
    .back-to-blog {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.89;
      letter-spacing: 0.64px;
      text-align: center;
      color: #2b2e42;
      text-transform: uppercase;
      margin: 60px 0;
    }
  }
  .latest-blog-posts {
    background: #dcecf3;
    padding: 80px 0;
    @media only screen and (max-width: 900px) {
      padding: 60px 0;
    }

    img {
      margin: 0 !important;
    }
  }
}
