.faq-container {
  dl {
    > div {
      margin-bottom: 1rem;

      dt {
        color: #2b2e42;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.75;
      }

      dd {
        margin: 0;
      }
    }
  }
}
