#app-download {
  .block {
    padding: 50px 0 100px;
    @media only screen and (max-width: 576px) {
      padding: 40px 24px 80px;
    }
  }

  #get-app {
    padding-top: 30px;
  }

  p {
    color: $secondary;
  }
}

.app-download-small-container {
  @media only screen and (max-width: 576px) {
    margin-top: 20px;
  }

  #app-download-small {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: solid 1px #c4ccd9;
    border-right: solid 1px #c4ccd9;
    border-left: solid 1px #c4ccd9;
    background-color: #ffffff;
    padding: 20px;

    p {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.5px;
    }

    a {
      text-decoration: none;
    }

    .shop-icon {
      margin-right: 20px;
    }

    .download-arrow {
      margin-left: 10px;
    }
  }

}

.floating-get-app {
  display: none;
  position: fixed;
  -webkit-transform: translateZ(0);
  top: 0;
  width: 100%;
  z-index: 50;
  background: #ffffff;
  font-size: 14px;
  padding: 15px 20px;
  box-shadow: 0 0.5px 0 0 #d1d6df, 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
